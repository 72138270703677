@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  svg {
    @apply inline-block;
  }

  img,
  video {
    @apply max-w-none;
  }
}

@layer components {
  .container {
    @apply mx-auto max-w-none w-full px-16 desktop:max-w-[80rem] desktop:px-32 desktopLarge:max-w-[98rem]
  }
}

@layer utilities {
  .card-wrapper {
    @apply p-[2px] rounded-4 relative overflow-hidden z-[1]
  }

  .card-wrapper::before {
    background-size: 100% 100%;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    @apply content-[''] absolute top-0 right-0 bottom-0 left-0 -z-[1] rounded-4;
  }
  
  .card-wrapper-legendary::before {
    background: #F06703;
    background: linear-gradient(180deg, #F06703 0%, #D67504 50%, #E4C000 100%);
  }

  .card-wrapper-legendary-black::before {
    background: #101010;
    background: linear-gradient(45deg, #101010 0%, #1D1D1D 50%, #A1A1A1 100%);
  }
}

.ss-button-widget {
  @apply !bottom-[7.5rem] !right-8 desktop:!bottom-[1.875rem] desktop:!right-[1.875rem]
}
